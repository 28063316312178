<template>
  <div v-if="userGroup == 'administrator'">
    <CCard>
      <CCardHeader>
        <strong>ລາຍງານອຸປະການ</strong>
      </CCardHeader>
      <CCardBody>
        <div style="min-height: 55vh">
          <ValidationObserver v-slot="{ invalid }">
            <CForm ref="form" :model="form" @submit.prevent="handleApplianceReport">
              <CRow>
                <CCol sm="4"></CCol>
                <CCol sm="4">
                  <CRow>
                    <CCol sm="12">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ເລືອກ :</label>
                          <v-select
                            prop="select"
                            v-model="form.select"
                            :options="selectList"
                            :reduce="code => code.code"
                            label="message"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="12" v-if="form.select == '2'">
                      <CInput label="ເລກ Serail :" prop="appl_serial" v-model="form.appl_serial" />
                    </CCol>
                    <CCol sm="12" v-if="form.select == '3'">
                      <div class="form-group">
                        <label>ເລືອກປະເພດອຸປະກອນ :</label>
                        <v-select
                          prop="appl_g_id"
                          v-model="form.appl_g_id"
                          :options="applGroup"
                          :reduce="appl_g_id => appl_g_id.appl_g_id"
                          label="appl_g_name"
                        />
                      </div>
                    </CCol>
                    <CCol sm="12">
                      <CButton
                        type="submit"
                        :disabled="invalid || loading"
                        color="info"
                        class="float-right my-2"
                        style="width: 8rem"
                      >
                        <span v-if="!loading">
                          <CIcon name="cil-check" />ສະແດງຂໍ້ມູນ
                        </span>
                        <CSpinner
                          v-if="loading"
                          color="light"
                          style="width: 1.3rem; height: 1.3rem;"
                        />
                      </CButton>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="4"></CCol>
              </CRow>
            </CForm>
          </ValidationObserver>
        </div>
      </CCardBody>
    </CCard>
    <CModal
      title="ລາຍງານອຸປະກອນທັງໝົດ"
      size="lg"
      :show.sync="modalApplianceTotal"
      :closeOnBackdrop="false"
    >
      <applianceTotal :appliance="appliance" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລາຍງານອຸປະກອນຕາມເລກ Serial"
      size="lg"
      :show.sync="modalApplianceById"
      :closeOnBackdrop="false"
    >
      <applianceById :applById="applById" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລາຍງານອຸປະກອນຕາມປະເພດ"
      size="lg"
      :show.sync="modalApplianceByGroup"
      :closeOnBackdrop="false"
    >
      <applianceByGroup :applByGroup="applByGroup" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import {
  getApplianceAPI,
  getApplianceByIdDetailAPI,
  getApplianceByGroupAPI,
} from "@/api/appliance";
import { getApplianceGroupAPI } from "@/api/applianceGroup";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import applianceTotal from "./components/ApplianceTotal";
import applianceById from "./components/ApplianceById";
import applianceByGroup from "./components/ApplianceByGroup";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ApplianceReport",
  components: {
    ValidationObserver,
    ValidationProvider,
    applianceTotal,
    applianceById,
    applianceByGroup,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      form: {
        select: "",
        appl_serial: "",
        appl_g_id: "",
      },
      selectList: [
        { code: "1", message: "ຂໍ້ມູນອຸປະກອນທັງຫມົດ" },
        { code: "2", message: "ຂໍ້ມູນອຸປະກອນ Serail" },
        { code: "3", message: "ຂໍ້ມູນອຸປະກອນຕາມປະເພດ" },
      ],
      modalApplianceTotal: false,
      modalApplianceById: false,
      modalApplianceByGroup: false,
      loading: false,
      applGroup: [],
      appliance: [],
      applById: {},
      applByGroup: {},
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    initialGetData() {
      getApplianceGroupAPI().then((response) => {
        this.applGroup = response.data.data;
      });
    },
    handleApplianceReport() {
      this.loading = true;

      if (this.form.select == "1") {
        getApplianceAPI()
          .then((response) => {
            this.modalApplianceTotal = true;
            this.appliance = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      } else if (this.form.select == "2") {
        getApplianceByIdDetailAPI(this.form.appl_serial)
          .then((response) => {
            this.modalApplianceById = true;
            this.applById = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      } else if (this.form.select == "3") {
        getApplianceByGroupAPI(this.form.appl_g_id)
          .then((response) => {
            this.modalApplianceByGroup = true;
            this.applByGroup = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.loading = false;
          });
      }
    },
  },
};
</script>