<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CButton @click="print()" color="info" style="width: 9rem" class="float-right">
          <CIcon name="cil-print" />ພິມລາຍງານ
        </CButton>
      </CCol>
      <CCol sm="12">
        <hr />
      </CCol>
      <CCol sm="12">
        <div id="print-applbygroup-view">
          <CRow>
            <CCol sm="8"></CCol>
            <CCol sm="3">
              <CImg src="images/3c_link_logo.png" width="100%" />
            </CCol>
            <CCol sm="1"></CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12" class="text-center">
              <h5>
                <u>
                  <b>ລາຍງານອຸປະກອນປະເພດ {{applByGroup.appl_g_name}}</b>
                </u>
              </h5>
            </CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ລະຫັດ</strong>
                            </th>
                            <th class="text-center">
                              <strong>Serial</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຊື່ອຸປະກອນ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຈໍານວນ<br />ໃນສາງ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຈໍານວນ<br />ຕິດຕັ້ງ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຈໍານວນ<br />ເປເພ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຫົວໜ່ວຍ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in applByGroup.listAppl" :key="row.appl_id">
                            <td class="text-center">{{row.appl_id}}</td>
                            <td>{{row.appl_serial}}</td>
                            <td>{{row.appl_name}}</td>
                            <td class="text-right">{{row.appl_total | numFormat }}</td>
                            <td class="text-center">{{row.appl_installed}}</td>
                            <td class="text-center">{{row.appl_broken}}</td>
                            <td class="text-center">{{row.appl_unit}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
export default {
  name: "ApplianceByGroup",
  props: {
    applByGroup: {
      type: Object,
      required: true,
    },
  },
  methods: {
    print() {
      this.$htmlToPaper("print-applbygroup-view");
    },
  },
};
</script>