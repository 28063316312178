<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CButton @click="print()" color="info" style="width: 9rem" class="float-right">
          <CIcon name="cil-print" />ພິມລາຍງານ
        </CButton>
      </CCol>
      <CCol sm="12">
        <hr />
      </CCol>
      <CCol sm="12">
        <div id="print-applbyid-view">
          <CRow>
            <CCol sm="8"></CCol>
            <CCol sm="3">
              <CImg src="images/3c_link_logo.png" width="100%" />
            </CCol>
            <CCol sm="1"></CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12" class="text-center">
              <h5>
                <u>
                  <b>ລາຍງານອຸປະກອນ</b>
                </u>
              </h5>
            </CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12">
                      <h5>1. ລາຍລະອຽດຂອງອຸປະກອນ</h5>
                    </CCol>
                    <CCol sm="3">
                      <CImg :src="getImagsePath(applById.appl_pic)" width="100%" />
                    </CCol>
                    <CCol sm="9"></CCol>
                    <CCol sm="12">
                      <strong>- ລະຫັດ : {{applById.appl_id}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ເລກ Serial : {{applById.appl_serial}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ເລກ Max Address : {{applById.appl_max_add}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ຊື່ອຸປະກອນ : {{applById.appl_name}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ລາຍລະອຽດ : {{applById.appl_detail}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ຈໍານວນໃສສາງ : {{applById.appl_total | numFormat }}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ຈໍານວນຕິດຕັ້ງແລ້ວ : {{applById.appl_installed | numFormat}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ຈໍານວນເປເພ : {{applById.appl_broken | numFormat}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ຫົວໜ່ວຍ : {{applById.appl_unit}}</strong>
                    </CCol>
                    <CCol sm="6">
                      <strong>- ຈັດໃນປະເພດ : {{applById.appl_g_name}}</strong>
                    </CCol>
                    <CCol sm="12">
                      <br />
                    </CCol>
                    <CCol sm="12">
                      <h5>2. ລາຍລະອຽດການເພີ້ມເຂົ້າສາງ</h5>
                    </CCol>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ລະຫັດ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ວັນທີ່</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຈໍານວນ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຜູ້ບັນທຶກເພີ້ມ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in applById.listApplAdd" :key="row.id">
                            <td class="text-center">{{row.id}}</td>
                            <td class="text-center">{{row.add_date}}</td>
                            <td class="text-right">{{row.add_amount | numFormat }}</td>
                            <td class="text-center">{{row.add_by}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                    <CCol sm="12">
                      <br />
                    </CCol>
                    <CCol sm="12">
                      <h5>3. ລາຍລະອຽດການເບິກຕິດຕັ້ງ</h5>
                    </CCol>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ລະຫັດ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ວັນທີ່</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຈໍານວນ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ເບີກໃຫ້ເລກທີ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ລະຫັດອຸປະກອນ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຜູ້ບັນທຶກເບີກ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in applById.listApplManage" :key="row.id">
                            <td class="text-center">{{row.id}}</td>
                            <td class="text-center">{{row.appl_date}}</td>
                            <td class="text-right">{{row.appl_amount | numFormat }}</td>
                            <td class="text-center">{{row.appl_for}}</td>
                            <td class="text-center">{{row.appl_remark}}</td>
                            <td class="text-center">{{row.appl_m_by}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                    <CCol sm="12">
                      <br />
                    </CCol>
                    <CCol sm="12">
                      <h5>4. ລາຍລະອຽດການປ່ຽນອຸປະກອນ</h5>
                    </CCol>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ລະຫັດ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ວັນທີ່</strong>
                            </th>
                            <th class="text-center">
                              <strong>ເລກ Serial ເຄື່ອງໃໝ່</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຜູ້ບັນທຶກປ່ຽນ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in applById.listApplChange" :key="row.id">
                            <td class="text-center">{{row.id}}</td>
                            <td class="text-center">{{row.change_date}}</td>
                            <td >{{row.appl_serial_new }}</td>
                            <td class="text-center">{{row.change_by}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                    <CCol sm="12">
                      <br />
                    </CCol>
                    <CCol sm="12">
                      <h5>5. ລາຍລະອຽດການການເປເພ</h5>
                    </CCol>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ລະຫັດ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ວັນທີ່</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຈໍານວນ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຜູ້ບັນທຶກເຄື່ອງເພ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in applById.listApplBroken" :key="row.id">
                            <td class="text-center">{{row.id}}</td>
                            <td class="text-center">{{row.broken_date}}</td>
                            <td class="text-right">{{row.broken_amount | numFormat }}</td>
                            <td class="text-center">{{row.broken_by}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
export default {
  name: "ApplianceById",
  props: {
    applById: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getImagsePath(appl_pic) {
      return "/api_3c_link/uploaded/images_appliance/" + appl_pic;
    },
    print() {
      this.$htmlToPaper("print-applbyid-view");
    },
  },
};
</script>